import { Box, Button, Input, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/slices/authSlice'
import { isTokenExpired } from '../utils/checkAuthorization'
import { supabase } from '../utils/supabase'
import CheckboxInput from './ui-elements/CheckboxInput'
import ColorInput from './ui-elements/ColorInput'
import FileInput from './ui-elements/FileInput'
import TextInput from './ui-elements/TextInput'
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { setBrandsFields } from '../store/slices/brandsFromStepSlice'
import { FaTrash } from 'react-icons/fa6'
import LogoInput from './ui-elements/LogoInput'
import { useNavigate } from 'react-router-dom'


const Form = ({
    fields,
    schema,
    setCurrentStep,
    currentStep,
    setFormValues,
    setLogoPreview,
    isLastStep,
    formValues,
    setActiveBrand
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userData } = useSelector((state) => state?.auth)
    const { brandsFields } = useSelector((state) => state.brandsFields)
    const [openSuccessToast, setOpenSuccessToast] = useState(false);
    const [openDeleteToastFailed, setOpenDeleteToastFaied] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const handleOpenSuccessToast = () => {
        setOpenSuccessToast(true);
    };


    const handleCloseSuccessToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSuccessToast(false);
    };
    const handleOpenFailedToast = () => {
        setOpenDeleteToastFaied(true);
    };

    const handleCloseFailedToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastFaied(false);
    };
    const [file, setFile] = useState(null)


    const uploadImage = async (item) => {
        if (!item?.brand_logo) {
            console.error('No brand_logo found:', item);
            return null;
        }

        try {
            const { data, error } = await supabase.storage
                .from('brands') // Replace 'brands' with your bucket name
                .upload(`images/${uuidv4()}-${item.brand_logo.name}`, item.brand_logo, {
                    upsert: true
                });

            if (error) {
                console.error('Error uploading file:', error.message);
                return null;
            }

            // console.log('Uploaded file path:', data.path);
            return data.path; // Return the path of the uploaded image
        } catch (error) {
            console.error('Unexpected error:', error.message);
            return null;
        }
    };

    const {
        handleSubmit,
        values,
        errors,
        setFieldValue,
        touched,
        resetForm,
        setValues
    } = useFormik({
        validationSchema: schema,
        initialValues: {
            ...formValues,
            button_color: '#37FF00',
            button_text_color: "#ffffff",
            title_background_color: '#ffffff',
            title_text_color: '#000000',
            spon_back_background_color: '#800000',
            brands: []
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async (formVals) => {
            if (isLastStep) {
                if (isTokenExpired(userData)) {
                    supabase.auth.signOut().then(() => {
                        dispatch(logout());
                    });
                } else {
                    setIsLoading(true)
                    console.log({ formVals, formValues });

                    // Process the uploadImage for each brand using Promise.all
                    try {
                        const uploadedImages = await Promise.all(
                            formValues.brands.map(async (item) => {
                                try {
                                    const res = await uploadImage(item);
                                    return {
                                        brand_link: item?.brand_link,
                                        brand_title: item?.brand_title,
                                        brand_id: item?.id,
                                        brand_logo_link: res,
                                    };
                                } catch (err) {
                                    console.error('Error uploading image for brand:', item, err);
                                    return null; // Return null or handle errors based on your requirements
                                }
                            })
                        );

                        // Filter out any null entries (failed uploads)
                        const validUploadedImages = uploadedImages.filter((img) => img !== null);

                        console.log({
                            whileCreatingAd: validUploadedImages,
                            formValBrands: formValues.brands,
                        });

                        // Proceed only if all uploads succeeded
                        if (validUploadedImages.length === formValues.brands.length) {
                            await supabase
                                .from('ads')
                                .insert({
                                    ...formVals,
                                    brands: validUploadedImages,
                                    id: uuidv4(),
                                })
                                .then((result) => {
                                    setIsLoading(false)
                                    console.log({ result });
                                    handleOpenSuccessToast();
                                    setCurrentStep(1);
                                    setFormValues(null);
                                    setValues({});
                                    resetForm();
                                    navigate(`/`)
                                });
                        } else {
                            setIsLoading(false)
                            console.error(
                                'Some brand images failed to upload:',
                                uploadedImages
                            );
                        }
                    } catch (error) {
                        setIsLoading(false)
                        console.error('Error processing uploads:', error);
                    }
                }
            } else {
                setCurrentStep((ps) => ps + 1);
            }
        }
    })

    const isValidPrevBrands = (array) => {
        for (const obj of array) {
            // Check if any property of the object is null or an empty string
            if (!obj.id || !obj.brand_title || !obj.brand_link || !obj.brand_logo) {
                return false;
            }
        }
        return true;
    }

    const handleAddRow = () => {
        // console.log({ brands: values['brands'] })
        if (isValidPrevBrands(values['brands'])) {
            const newBrand = {
                id: uuidv4(),
                brand_title: '',
                brand_logo: null,
                brand_logo_preview: null,
                brand_link: '',
                // isNew:false
            }
            setFieldValue('brands', [
                ...values?.['brands'],
                newBrand
            ])
            setActiveBrand(newBrand.id)
            // console.log({ formValues })
            const tempBrands = !!formValues?.brands?.length ? [...formValues?.brands] : []
            tempBrands.push(newBrand)

            setFormValues('brands', tempBrands)

        }
        else {
            alert('Please insert all values in previous brands')
        }
    }
    const handleUpdate = (e, id) => {
        const { value, name } = e.target
        // console.log({ formValues, values })
        const tempBrands = [...formValues?.['brands']]
        const currentBrandIndex = tempBrands.findIndex((el) => el.id === id)
        tempBrands[currentBrandIndex] = {
            ...tempBrands[currentBrandIndex],
            [name]: value
        }
        setFieldValue('brands', tempBrands)
        setFormValues('brands', tempBrands)
    }
    const handleDeleteBrand = (id) => {
        console.log({ id, values, formValues })
        const tempBrands = [...formValues['brands']].filter((item) => item.id !== id)
        setFieldValue('brands', tempBrands)
        setFormValues('brands', tempBrands)
    }
    return (
        <>
            <form onSubmit={handleSubmit}>
                {
                    currentStep === 2 ? brandsFields.map(({ formFields }, index) => {
                        return <>
                            <Box>
                                <Box display='flex'>
                                    {
                                        formFields.map((field, i) => {
                                            return <Typography flex={1} sx={{
                                                border: 'solid 1px #ccc',
                                                p: '5px'
                                            }} key={i}>{field.label}</Typography>
                                        })
                                    }
                                    <Typography flex={1} sx={{
                                        border: 'solid 1px #ccc',
                                        p: '5px'
                                    }}>Action</Typography>
                                </Box>
                                <Box>
                                    {
                                        !!formValues?.['brands']?.length && formValues?.['brands'].map((item, index) => {
                                            return <Box display={'flex'}>
                                                <LogoInput formValues={formValues} item={item} setFormValues={setFormValues} />
                                                <Box flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px',
                                                    display: 'flex',
                                                    alignItems: 'end'
                                                }}>
                                                    <Input sx={{
                                                        border: 'none',
                                                        width: '100%',
                                                    }} value={item?.brand_title} name={'brand_title'} onChange={(e) => handleUpdate(e, item.id)} />
                                                </Box>
                                                <Box flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px'
                                                }}>
                                                    <Input sx={{
                                                        border: 'none',
                                                        width: '100%'
                                                    }} value={item?.brand_link} name={'brand_link'} onChange={(e) => handleUpdate(e, item.id)} />
                                                </Box>
                                                <Box display={'flex'} justifyContent="center" alignItems={"center"} flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px'
                                                }}>
                                                    <FaTrash onClick={() => handleDeleteBrand(item.id)} />
                                                </Box>
                                            </Box>
                                        })
                                    }
                                </Box>
                            </Box>
                            <Button sx={{ mt: '20px', width: '100%' }} disabled={1 === 2} onClick={handleAddRow} variant='contained'>Add brand</Button>
                        </>
                    })
                        : fields?.map(({ label, placeholder, type, name }) => {
                            if (type === 'checkbox') {
                                return <CheckboxInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    value={values[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                            }
                            else if (type === 'color') {
                                return <ColorInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    value={values[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                />
                            }
                            else if (type === 'file') {
                                return <FileInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    value={values[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                    setLogoPreview={setLogoPreview}
                                    setFile={setFile}
                                />
                            }
                            return (
                                <TextInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    placeholder={placeholder}
                                    value={values[name]}
                                    error={errors[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                />
                            )
                        })
                }
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button sx={{ mt: '20px', width: '150px' }} disabled={currentStep === 1} onClick={() => setCurrentStep(ps => ps - 1)} variant='contained'>Back</Button>
                    <Button disabled={isLoading} sx={{ mt: '20px', width: '150px' }} type='submit' variant='contained'>{isLastStep ? `${isLoading ? 'Submitting' :'Submit'}` : 'Next'}</Button>
                </Box>
            </form>
            <Snackbar open={openSuccessToast} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
                <Alert
                    onClose={handleCloseSuccessToast}
                    severitFailed="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Item created successfully
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteToastFailed} autoHideDuration={6000} onClose={handleCloseFailedToast}>
                <Alert
                    onClose={handleCloseFailedToast}
                    severitFailed="error"
                    variant="filled"
                    sx={{ width: '100%', bgcolor: 'red' }}
                >
                    Failed to create item
                </Alert>
            </Snackbar>
        </>
    )
}

export default Form