import * as Yup from 'yup';

// const fileSchema = Yup.mixed().test(
//   'fileType',
//   'Brand logo must be an image',
//   (value) => {
//     if (!value) return true; // Skip validation if value is not present
//     return value instanceof File && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
//   }
// );

export const add_form_step_1_schema = Yup.object({
    title: Yup.string().required('Required'),
    title_background_color: Yup.string().nullable(),
    title_text_color: Yup.string().nullable(),
    sponsored: Yup.boolean().nullable(),
    spon_back_background_color: Yup.string().nullable(),
    finder_award: Yup.boolean().nullable(),
    finder_award_text: Yup.string().nullable(),
    finder_award_tooltip_text: Yup.string().nullable(),
})
// export const add_form_step_1_2_schema = Yup.object({
//     brands: Yup.array().of(
//         Yup.object().shape({
//             brand_title: Yup.string().required('Brand title is required'),
//             brand_logo: fileSchema,
//             brand_link: Yup.string().url('Brand link must be a valid URL').required('Brand link is required'),
//         })
//     ).min(1, 'At least one brand is required').max(3, 'You can have a maximum of 3 brands')
// })
const fileSchema = Yup.mixed().test(
    'fileType',
    'Brand logo must be an image',
    (value) => {
      if (!value) return true; // Skip validation if value is not present
      return value instanceof File && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
    }
  );
  
  export const add_form_step_1_2_schema = Yup.object({
    brands: Yup.array()
      .of(
        Yup.object().test(
          'caseValidation',
          'Each brand object must match Case 1 or Case 2',
          (value) => {
            // Validate Case 1
            const isCase1 =
              typeof value.brand_id === 'string' &&
              value.brand_id.trim() !== '' &&
              typeof value.brand_link === 'string' &&
              value.brand_link.trim() !== '' &&
              typeof value.brand_logo_link === 'string' &&
              value.brand_logo_link.trim() !== '' &&
              typeof value.brand_title === 'string' &&
              value.brand_title.trim() !== '';
  
            // Validate Case 2
            const isCase2 =
              typeof value.brand_link === 'string' &&
              value.brand_link.trim() !== '' &&
              typeof value.brand_title === 'string' &&
              value.brand_title.trim() !== '' &&
              typeof value.id === 'string' &&
              value.id.trim() !== '' &&
              typeof value.brand_logo_preview === 'string' &&
              value.brand_logo_preview.trim() !== '' &&
              fileSchema.isValidSync(value.brand_logo);
  
            // Return true if either Case 1 or Case 2 is valid
            return isCase1 || isCase2;
          }
        )
      )
      .min(1, 'At least one brand is required')
      .max(10, 'You can have a maximum of 3 brands')
  });
  

// export const add_form_step_2_schema = Yup.object({
//     description: Yup.string().required('Required'),
//     description_back_color: Yup.string().nullable(),
//     description_text_color: Yup.string().nullable(),
//     t_and_c_apply: Yup.boolean().nullable(),
//     button_color: Yup.string().nullable(),
//     button_text_color: Yup.string().nullable(),
//     button_text: Yup.string().nullable(),
//     read_review_link: Yup.string()
//     .nullable()
//     .url('Must be a valid URL'), // Ensures valid URL when provided
//     read_review_button_text: Yup.string().nullable(),
//     read_review_button_text_color: Yup.string().nullable(),
// });

export const add_form_step_2_schema = Yup.object({
  description: Yup.string().required('Required'),
  description_back_color: Yup.string().nullable(),
  description_text_color: Yup.string().nullable(),
  t_and_c_apply: Yup.boolean().nullable(),
  button_color: Yup.string().nullable(),
  button_text_color: Yup.string().nullable(),
  button_text: Yup.string().nullable(),
  read_review_link: Yup.string().nullable().url('Must be a valid URL'),
  read_review_button_text: Yup.string().nullable(),
  read_review_button_text_color: Yup.string().nullable(),
}).test(
  'read-review-fields',
  'Either "Read Review Link" or "Read Review Button Text" must be provided when one is entered.',
  function (values) {
    const { read_review_link, read_review_button_text } = values;
    if (!read_review_link && !read_review_button_text) {
      return true; // Both are optional when not provided
    }
    if (read_review_link && !read_review_button_text) {
      return this.createError({
        path: 'read_review_button_text',
        message: '"Read Review Button Text" is required when "Read Review Link" is provided.',
      });
    }
    if (!read_review_link && read_review_button_text) {
      return this.createError({
        path: 'read_review_link',
        message: '"Read Review Link" is required when "Read Review Button Text" is provided.',
      });
    }
    return true; // Both are provided, no error
  }
);



export const login_schema = Yup.object({
    phone_number: Yup.string().required('Phone'),
    otp: Yup.string().required('Phone'),
})