import { Box, Typography } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { IoMdCheckmark } from 'react-icons/io';
import { useNavigate } from 'react-router-dom'
import { setFormData } from '../store/slices/formSlice';
import { IoClose } from 'react-icons/io5';
import { LuClipboardCheck } from "react-icons/lu";
import { isTokenExpired } from '../utils/checkAuthorization';
import { supabase } from '../utils/supabase';
import { getCardsData } from '../store/slices/dataSlice';
import { logout } from '../store/slices/authSlice';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const CardsRenderer = ({ setIsOpenDeleteModal, setDeletingItemId }) => {
    const { cards } = useSelector((state => state?.data))
    const { userData } = useSelector((state) => state?.auth)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isOpenToast, setIsOpenToast] = useState(false)
    useEffect(() => {
        fetchData()
    }, [])
    const handleCopyToClipboard = async (id) => {
        // try {
        //     const copiedText = await navigator.clipboard.writeText(`https://admanager.agrtech.com.au/api/supabase/supa-data/${id}`)
        //     console.log({ copiedText })
        //     if (copiedText) {
        //         setIsOpenToast(true)
        //     }
        // } catch (error) {
        //     alert('Error')
        //     console.log({ error })
        // }
        // await navigator.clipboard.writeText(`https://admanager.agrtech.com.au/api/supabase/supa-data/${id}`).then((res) => {
        //     setIsOpenToast(true)
        // })
        await navigator.clipboard.writeText(`<iframe src="https://admanager.agrtech.com.au/api/adtech/${id}" width="600px" height="800px" style="border:none;"></iframe>`).then((res) => {
            setIsOpenToast(true)
        })
    }
    const fetchData = async () => {
        try {
            if (isTokenExpired(userData)) {
                supabase.auth.signOut().then(() => {
                    dispatch(logout())
                })
            }
            else {

                const { data, error } = supabase.from('ads').select('*').then((rs) => {
                    // console.log({ rs, data, error })
                    // dispatch(getCardsData(rs.data))
                    // setLoading(false);
                    // console.log({ data, error })
                    if (rs.error) {
                        setError(rs.error || 'Failed to fetch');
                        setLoading(false);
                    }
                    else {
                        dispatch(getCardsData(rs.data))
                        setLoading(false);
                    }
                })
            }
            setLoading(true)
        } catch (err) {
            setError(err);
            setLoading(false);
            console.log({ eerror: err })
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error occurred: {error.message}</div>;
    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpenToast(false);
    };

    return (
        <Fragment>
            <Snackbar open={isOpenToast} autoHideDuration={6000} onClose={handleCloseToast}>
                <Alert
                    onClose={handleCloseToast}
                    severitFailed="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Embeded Code Copied
                </Alert>
            </Snackbar>
            {
                // cards.map(({ logo, title, description, sponsored, id }, index) => (
                Array.isArray(cards) ?
                    cards.map((item, index) => (
                        <Box sx={{ display: 'flex', mt: '10px', borderBottom: 'solid 1px #ccc' }} flex key={index}>
                            <Box sx={{ flex: 1 }}><Typography>{index + 1}</Typography></Box>
                            {/* <Box sx={{ flex: 1 }}><Box component="img" alt="Logo" src={item?.logo} sx={{ width: '20px', height: '20px' }} /></Box> */}
                            <Box sx={{ flex: 1 }}><Typography>{item?.title}</Typography></Box>
                            <Box sx={{ flex: 1 }}><Typography>{item?.description}</Typography></Box>
                            <Box sx={{ flex: 1 }}>{item?.sponsored ? <IoMdCheckmark />
                                : <IoClose />}</Box>
                            <Box sx={{ flex: 1, cursor: 'pointer' }}>{item?.sponsored ? <LuClipboardCheck onClick={() => handleCopyToClipboard(item.id)} title='Copy to clipboard' />
                                : <LuClipboardCheck onClick={() => handleCopyToClipboard(item.id)} />}</Box>
                            <Box sx={{ flex: 1, display: 'flex' }}>
                                <EditOutlinedIcon onClick={() => {
                                    dispatch(setFormData({
                                        ...item
                                    }))
                                    navigate(`update-unit/${item.id}`)
                                }} />
                                {/* <DeleteOutlineOutlinedIcon onClick={() => handleDeleteAd(id)} /> */}
                                <DeleteOutlineOutlinedIcon onClick={() => {
                                    setDeletingItemId(item?.id)
                                    setIsOpenDeleteModal(true)
                                }} />
                            </Box>
                        </Box>
                    ))
                    : <div>No items available</div>
            }
        </Fragment>
    )
}

export default CardsRenderer